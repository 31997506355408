import React, { useState } from 'react'
import Router, { useRouter } from 'next/router'
import pageService from '@/services/http/wap/page'
import { headerprops } from './type'
import Image from '@/components/base/image/Image'
import Anchor from '@/components/base/anchor/Anchor'
import { app } from '@/core/utils/path'
import { useApp } from '@/core/contexts/app'
import Icon from '@/components/base/icon'
import { useWidth } from '@/core/hooks/useWidthResize'
import { classnames } from 'begonya/functions'
import { Column, Container, Row } from '@/components/base/gridview'
import { path } from '@/core/utils'
import Button from '@/components/base/button/Button'

const UlupinarHeader: React.FunctionComponent<headerprops> = ({
  logo,
  hdrnavigation,
  menunavigation,
  isHome,
}) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const app = useApp()
  const width = useWidth()
  const router = useRouter()

  const handleLanguageSelection = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  const renderLanguageSelection = () => {
    if (app.languages.length > 1) {
      return (
        <div className="language-selection">
          {width > 1100 ? (
            <>
              <div className="language-selection-current">
                <Button onClick={openLanguage}>
                  {app.language.substring(0, 2)}
                </Button>
              </div>
              {isDropdownOpen && (
                <div className="language-selection-dropdown">
                  <div className="language-selection-dropdown-list">
                    {app.languages.map((language) => (
                      <li key={language.id}>
                        <Button
                          onClick={() => {
                            handleLanguageSelection(language.id)
                            setIsDropdownOpen(false)
                          }}
                        >
                          {language.title}
                        </Button>
                      </li>
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <ul className="language-selection-list">
              {app.languages.map((language) => (
                <li key={language.id}>
                  <Button
                    onClick={() => {
                      handleLanguageSelection(language.id)
                      setIsDropdownOpen(false)
                    }}
                  >
                    {language.name.substring(0, 2)}
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
      )
    } else {
      return null // Dil seçeneği yoksa null döndürüyoruz veya başka bir şey yapabilirsiniz.
    }
  }

  const renderMenuIcon = () => {
    if (openMenu) {
      return <Icon name="clear" size={'medium'} />
    } else {
      return <Icon name="menu" size={'medium'} />
    }
  }

  const openLanguage = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  return (
    <div
      className="ulupinar-header"
      style={{
        position: isHome ? 'absolute' : 'relative',
      }}
    >
      <Container size="medium">
        <Row xs={{ align: 'center', justify: 'between' }}>
          <Column>
            <Anchor href={app.settings.homepage}>
              <Image
                className="ulupinar-header-logo"
                {...(logo?.src ? logo : app.theme.logo)}
                alt=""
              />
            </Anchor>
          </Column>
          <Column style={{ display: 'flex', alignItems: 'center' }}>
            {width > 1100 ? (
              <React.Fragment>
                <div className="ulupinar-header-navigation-menu">
                  <ul>
                    {hdrnavigation?.map((menuItem, index) => (
                      <li
                        className={classnames(
                          app.page.path === menuItem.href && 'active'
                        )}
                        key={index}
                      >
                        {menuItem.children.length ? (
                          <Anchor
                            style={{
                              color: isHome ? '#fff' : '#000',
                            }}
                            dangerouslySetInnerHTML={{ __html: menuItem.title }}
                          />
                        ) : (
                          <Anchor
                            style={{
                              color: isHome ? '#fff' : '#000',
                              textShadow: isHome ? '2px 2px 4px #000' : '',
                            }}
                            href={menuItem.href}
                            dangerouslySetInnerHTML={{ __html: menuItem.title }}
                          />
                        )}

                        {menuItem.children.length ? (
                          <ul className="sub-menu">
                            {menuItem.children.map((subItem, subIndex) => (
                              <li key={subIndex}>
                                <Anchor href={subItem.href}>
                                  {subItem.title}
                                </Anchor>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                {renderLanguageSelection()}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button
                  onClick={() => {
                    setOpenMenu(!openMenu)
                  }}
                  className="open"
                >
                  {renderMenuIcon()}
                </button>
                <div
                  className={classnames(
                    'menu-panel',
                    openMenu && 'menu-panel--active'
                  )}
                >
                  <Container>
                    <Row xs={{ justify: 'between', align: 'center' }}>
                      <Column>
                        <Anchor
                          onClick={() => {
                            setOpenMenu(false)
                          }}
                          href={app.settings.homepage}
                        >
                          <Image
                            className="ulupinar-header-logo"
                            {...(logo?.src ? logo : app.theme.logo)}
                            alt=""
                            width={200}
                            height={100}
                          />
                        </Anchor>
                      </Column>
                      <Column>
                        <button
                          onClick={() => {
                            setOpenMenu(!openMenu)
                          }}
                          className="close"
                        >
                          {renderMenuIcon()}
                        </button>
                      </Column>
                    </Row>
                    <Row>
                      <Column>
                        <ul>
                          {menunavigation?.map((menuItem, index) => (
                            <li
                              className={classnames(
                                app.page.path === menuItem.href && 'active'
                              )}
                              key={index}
                            >
                              <Anchor
                                onClick={() => {
                                  setOpenMenu(false)
                                }}
                                href={menuItem.href}
                                dangerouslySetInnerHTML={{
                                  __html: menuItem.title,
                                }}
                              />
                            </li>
                          ))}
                        </ul>
                      </Column>
                    </Row>
                    <Row>
                      <Column>{renderLanguageSelection()}</Column>
                    </Row>
                  </Container>
                </div>
              </React.Fragment>
            )}
          </Column>
        </Row>
      </Container>
    </div>
  )
}

export default UlupinarHeader
